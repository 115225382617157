import { Injectable } from "@angular/core";
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from "@angular/common/http";
import { environment } from "src/environments/environment";
import { Router } from "@angular/router";
import {Observable, of, throwError} from 'rxjs';
import {mergeMap, delay, retryWhen} from 'rxjs/operators';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  private API_KEY = environment.API_KEY;

  constructor(private router: Router) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const authReq = this.generateHeader(req);
    // return next.handle(authReq);
    return next.handle(authReq).pipe(
      retryWhen((error) =>{
        return error.pipe(
          mergeMap((error, index) => {
            this.handleError(error)
            throw error;
          })
        )
      }
      )
    )
  }
  private handleError(err: any){
    console.log(err);
    if (err?.status === 401 || err?.status === 403) {
      this.router.navigate(["auth", "login"]);
        //return Observable.of(err.message);
    }
  }
  generateHeader(req: HttpRequest<any>): HttpRequest<any> {
    let responseValue;
    const callCtrl = localStorage.getItem("call-ctrl");
    const userDataString:any=localStorage.getItem("user");
    const userData= JSON.parse(userDataString);
    const contentType = req.url.includes("connect/token")
      ? "application/x-www-form-urlencoded"
      : "application/json; charset=utf-8";

    if (userData === undefined || userData === null) {
      responseValue = req.clone({
        headers: req.headers
          .set("Content-Type", contentType)
          .set("Accept", "application/json")
          .set("x-api-key", environment.API_KEY)
          .set("secret-api-key", environment.SECRET_API_KEY)
          .set("call-ctrl", callCtrl ? callCtrl : "-"),
      });
    } else {
      const token = userData.rawId;
      responseValue = req.clone({
        headers: req.headers
          .set("Content-Type", contentType)
          .set("Accept", "application/json")
          .set("x-api-key", this.API_KEY)
          .set("call-ctrl", callCtrl ? callCtrl : "-")
          .set("Authorization", "Bearer " + token)
          .set("secret-api-key", environment.SECRET_API_KEY),
      });
    }
    return responseValue;
  }

  
}
