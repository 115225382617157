export const environment = {
  production: false,
  baseUrl: "localhost:4200",
  //API_URL: 'https://api.martigo.com/v1/MartiGo',
  API_URL: "https://sandbox.gate.martigo.com",
  API_KEY: "c2966429-193f-4189-b1b5-23af8e2855c3",
  SECRET_API_KEY: "23af8e2855c3",
  EDITOR_API_KEY: "99rs14hpc9xm4gqrtlbog48w6fcr4obr0a0dt6i9qpigva11",
  EDITOR_CUSTOM_OPTIONS: {
    height: 350,
    menubar: false,
    base_url: "/tinymce",
    content_css: "/assets/theme/css/tinymce-content.css",
    suffix: ".min",
    auto_focus: false,
    fontsize_formats: "8pt 10pt 12pt 14pt 18pt 24pt 36pt",
    plugins:
      "print preview   searchreplace autolink directionality  visualblocks visualchars fullscreen image link media template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists textcolor wordcount  imagetools  contextmenu colorpicker textpattern code",
    toolbar:
      "removeformat bold italic underline strikethrough | table | fontsizeselect formatselect | lineheight | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist checklist | forecolor backcolor casechange permanentpen formatpainter | pagebreak | charmap emoticons | fullscreen  preview save print | insertfile image media pageembed template link anchor codesample | showcomments addcomment code",
    line_height_formats: "1 1.2 1.4 1.6 2",
    table_responsive_width: true,
  },
};
