
    <div class="offcanvas-md offcanvas-end bg-body-tertiary sidebar" tabindex="-1" id="sidebarMenu" aria-labelledby="sidebarMenuLabel">
      <div class="offcanvas-header">
        <h5 class="offcanvas-title" id="sidebarMenuLabel">Company name</h5>
        <button type="button" class="btn-close" data-bs-dismiss="offcanvas" data-bs-target="#sidebarMenu" aria-label="Close"></button>
      </div>
      <div class="offcanvas-body d-md-flex flex-column p-0 pt-lg-3 overflow-y-auto">
        <!--
        <h6 class="sidebar-heading d-flex justify-content-between align-items-center px-3 mt-4 mb-1 text-body-secondary text-uppercase">
          <span>Saved reports</span>
          <a class="link-secondary" href="#" aria-label="Add a new report">
            <svg class="bi"><use xlink:href="#plus-circle"/></svg>
          </a>
        </h6>
        <hr class="my-1">
        -->
        <ul class="nav flex-column">
          <li class="nav-item" *ngFor="let m of menu; let i=index">
            <a class="nav-link d-flex align-items-center gap-2 text-primary-emphasis fw-normal" [ngClass]="{'active':activeChild==='/'+m}" aria-current="page" routerLink="/{{m}}">
              <span class="material-icons" [ngClass]="(i==1 || i==6)?'icon-danger':'icon-success'">settings</span>
              {{m}}
            </a>
          </li>
          
          
        </ul>
  
      </div>
    </div>
   
  