<header class="navbar sticky-top bg-martigo flex-md-nowrap p-0 justify-content-between">
    <a class="navbar-brand col-md-3 col-lg-2 me-0 px-3 fs-6 text-white" href="#">
      <img src="https://b2b.martigo.com/assets/img/logo.svg" height="40" alt="">
    </a>
    <div></div>
    <div class="d-flex align-items-center">
        <span class="badge mr-4 font-weight-normal" style="font-size:12px"
                [ngClass]="isProduction?'bg-success':'bg-secondary'"> {{isProduction? 'Live':'Development'}}</span>
          <a (click)="logout()" class="text-white mx-4"><i class="material-icons text-white">
            power_settings_new
          </i></a>
    </div>
    <ul class="navbar-nav flex-row d-md-none">
    mobil
    </ul>
    
    </header>