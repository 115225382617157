import * as store from 'store';
import * as actions from './actions';

const STORED_SETTINGS = (storedSettings: any) => {
  const settings:any = {};
  Object.keys(storedSettings).forEach(key => {
    const item = store.get(`app.settings.${key}`);
    settings[key] = typeof item !== 'undefined' ? item : storedSettings[key];
  });
  return settings;
};

export const initialState: object = {
  // default settings, if not exist in localStorage
  ...STORED_SETTINGS({
    locale: 'en-US',
    isSidebarOpen: false,
    isProduction: true,
    last10FlightList: [],
  }),
};

export function reducer(state = initialState, action: any): object {
  switch (action.type) {
    case actions.SET_STATE:
      const key = Object.keys(action.payload)[0];
      store.set(`app.settings.${key}`, action.payload[key]);
      return {...state, ...action.payload};
    default:
      return state;
  }
}

export const getSettings = (state: any) => state;
