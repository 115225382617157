import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router, CanActivate } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from 'src/app/core/http/auth.service';

@Injectable({
  providedIn: 'root'
})
export class LoginGuard implements CanActivate {
  constructor(
    private authService: AuthService,
    public router: Router,
    // private notification: NotificationService
  ) {

  }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    const logged = this.authService.isLoggin();

    if (logged) {
      return true;
    }
    // this.notification.error('Bu Sayfaya Giriş yetkiniz yok !');
    this.router.navigate([ 'auth', 'login' ]);
    return false;
  }
}
