import { Injectable } from "@angular/core";
import { BaseService } from "./base.service";
import { HttpClient } from "@angular/common/http";
import { UserLoginResponse } from "../models/response/user-login-response";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class AuthService {
  constructor(private baseService: BaseService, private http: HttpClient) {}

  login(username: string, password: string): Promise<UserLoginResponse> {
    const request:any = {
      username: username,
      password: password,
      client_id: environment.API_KEY,
      client_secret: environment.SECRET_API_KEY,
      grant_type: "password",
      scope:
        "openid profile email phone address IdentityServerApi offline_access martigo.getway.api martigo.content.api martigo.agency.api role permission",
    };

    const body = `${Object.keys(request)
      .map((el) => `${el}=${request[el]}`)
      .join("&")}`.replace("@", "%40");
    return this.http
      .post(this.baseService.serviceUrl + "/gateaccount/connect/token", body)
      .toPromise()
      .then((resp) => resp as UserLoginResponse);
  }

  register() {}

  isActive() {}

  isLoggin(): boolean {
    // token kontrolü eklenecek
    const userData = JSON.parse(localStorage.getItem("user") || '{}');
    if (userData === null || userData === undefined) {
      return false;
    }
    return true;
  }
}
