import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {select, Store} from '@ngrx/store';
import * as Reducers from 'src/app/store/reducers';
import * as SettingsActions from '../../../store/settings/actions';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  isProduction = false;
  isSidebarOpen = true;
  
  constructor(private router: Router,
    private store: Store<any>,) { }

  ngOnInit(): void {
    this.store.pipe(select(Reducers.getSettings)).subscribe(state => {
      this.isProduction = state.isProduction;
      this.isSidebarOpen = state.isSidebarOpen;
    });
  }
  logout() {
    localStorage.removeItem('user');
    this.router.navigate(['auth', 'login']);
  }
}
