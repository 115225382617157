import { Injectable } from "@angular/core";

import { HttpClient } from "@angular/common/http";
import { Router } from "@angular/router";
import { select, Store } from "@ngrx/store";
import * as Reducers from "../../store/reducers";

@Injectable({
  providedIn: "root",
})
export class BaseService {
  private isProduction = false;
  public serviceUrl:string='';

  constructor(
    private http: HttpClient,
    private store: Store<any>,
    private router: Router
  ) {
    this.store.pipe(select(Reducers.getSettings)).subscribe((state) => {
      this.isProduction = state.isProduction;
      if (this.isProduction) {
        this.serviceUrl = "https://gate.martigo.com";
      } else {
        this.serviceUrl = "https://sandbox.gate.martigo.com";
      }
    });
  }

  getAll(url:string, param:any) {
    const urlParam:any = param
      ? `${this.serviceUrl}${url}?${Object.keys(param)
          .map((el) => `${el}=${param[el]}`)
          .join("&")}`
      : `${this.serviceUrl}${url}`;
    console.log(urlParam);

    return this.http
      .get(urlParam)
      .toPromise()
      .then((response) => {
        return response;
      })
      .catch((e) => {
        this.errorHandler(e);
      });
  }
  post(url:string, req:any) {
    return this.http
      .post(this.serviceUrl + url, JSON.stringify(req))
      .toPromise()
      .then((response) => {
        return response;
      })
      .catch((e) => {
        this.errorHandler(e);
      });
  }
  getById(url:string, param:string) {
    return this.http
      .post(this.serviceUrl + url, param)
      .toPromise()
      .then((response) => {
        return response;
      });
  }


  private errorHandler(e: any) {
    console.log("eee", e);
    if (
      e.error &&
      e.error.Message === "Authorization has been denied for this request."
    ) {
      localStorage.removeItem("user");
      this.router.navigate(["auth", "login"]);
    }
  }
}
