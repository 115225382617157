<app-header></app-header>
    
    <div class="container-fluid">
    <div class="row">
    <div class="sidebar border border-right col-md-3 col-lg-2 p-0 bg-body-tertiary">  
      <app-sidebar></app-sidebar>
    </div>
    <main class="col-md-9 ms-sm-auto col-lg-10 px-md-4">

        <router-outlet #outlet="outlet"></router-outlet>
    </main>
    </div>
    </div>
