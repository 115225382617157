import {ActionReducer, ActionReducerMap, createFeatureSelector, createSelector, MetaReducer,} from '@ngrx/store';
import {environment} from 'src/environments/environment';
import * as fromRouter from '@ngrx/router-store';
import * as fromSettings from './settings/reducers';

export const reducers: ActionReducerMap<any> = {
  router: fromRouter.routerReducer,
  settings: fromSettings.reducer,
};

export function logger(reducer: ActionReducer<any>): ActionReducer<any> {
  return (state: any, action: any): any => {
    const result = reducer(state, action);
    return result;
  };
}

export const metaReducers: MetaReducer<any>[] = !environment.production ? [logger] : [];

export const getSettingsState = createFeatureSelector<any>('settings');

export const getSettings = createSelector(
  getSettingsState,
  fromSettings.getSettings,
);
